<template>
  <div class="editUserWrap">
    <el-form
      :model="userForm"
      status-icon
      ref="userForm"
      label-width="100px"
      class="userForm"
      :rules="userFormRules"
    >
      <el-form-item
        v-for="f in eidtableFormItems"
        :key="f.prop"
        :label="f.label"
        :prop="f.prop"
      >
        <el-input v-model.number="userForm[f.prop]"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm()">提交</el-button>
        <el-button @click="$router.push('/userManage/users')">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userForm: {},
      userInfo: {},
      eidtableFormItems: [
        {
          prop: "projects",
          label: "工程数量",
        },
        {
          prop: "pages",
          label: "页面数量",
        },
        {
          prop: "free_capacity",
          label: "享用空间数量",
        },
      ],
      userFormRules: {
        projects: [
          { required: true, message: `请输入工程数量`, trigger: "blur" },
        ],
        pages: [{ required: true, message: `请输入页面数量`, trigger: "blur" }],
        free_capacity: [
          { required: true, message: `请输入享用空间数量`, trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      this.$request.queryUserById({ id: this.$route.query.id }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        this.userInfo = res.data || {};
        this.userForm = {
          free_capacity: res?.data?.free_capacity,
          pages: res?.data?.pages,
          projects: res?.data?.projects,
        };
      });
    },
    submitForm() {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.$request
            .updateUserStatus({ ...this.userForm, id: this.$route.query.id })
            .then((res) => {
              if (res.status != "success") {
                this.$message.error(res.msg);
                return;
              }
              this.$router.push("/userManage/users");
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang='scss'>
.userForm {
  background-color: #fff;
  padding: 24px 30% 24px 24px;
  .el-form-item__label {
    width: 30% !important;
  }
  .el-form-item__content {
    margin-left: 30% !important;
    .el-select {
      display: block;
    }
  }
}
</style>